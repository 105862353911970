import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade';

import canberra from '../assets/images/canberra-1.jpg'
import bunda from '../assets/images/bunda-3.jpg'
import waverley from '../assets/images/hero-144a.jpg'
import peel from '../assets/images/peel-hero.jpg'
import bourke from '../assets/images/bourke.jpg'
import comingSoon4 from '../assets/images/Coming-Soon-4.jpg'
import frankston from '../assets/images/nepean454-hero.jpg'
import young from '../assets/images/young.jpg'
import flinders from '../assets/images/flinders-1.jpg'

//import pdf from '../assets/images/project.jpg'


class Banner extends Component {

  /*
  <li>
    <a href={pdf} target="_blank" rel="noopener noreferrer">
      <img src={project} alt="220 Albert Street" />
    </a>
    <p><strong>220 Albert Street</strong></p>
    <a href={pdf} target="_blank" rel="noopener noreferrer">Click to download PDF</a>
  </li>
  */

  render() {
    return (
      <section className="projects">
        <Fade right cascade distance="20px">
          <ul>
            <li>
              <Link to="/bourke-st">
                <img src={bourke} alt="Bourke Street" />
              </Link>
              <Link to="/bourke-st">Bourke Street, Melbourne VIC</Link>
            </li>
            <li>
              <Link to="/flinders-st">
                <img src={flinders} alt="Flinders Street" />
              </Link>
              <Link to="/flinders-st">Flinders Street, Melbourne VIC</Link>
            </li>
            <li>
              <Link to="/young-st">
                <img src={young} alt="Young Street" />
              </Link>
              <Link to="/young-st">Young Street, Albury NSW</Link>
            </li>
            <li>
              <Link to="/denison-st">
                <img src={canberra} alt="Denison Street" />
              </Link>
              <Link to="/denison-st">Denison Street, Canberra ACT</Link>
            </li>
            <li>
              <Link to="/nepean-hwy">
                <img src={frankston} alt="Nepean Highway" />
              </Link>
              <Link to="/nepean-hwy">Nepean Highway, Frankston VIC</Link>
            </li>
            <li>
              <Link to="/peel-st">
                <img src={peel} alt="Peel St" />
              </Link>
              <Link to="/peel-st">Peel Street, Collingwood VIC</Link>
            </li>
            <li>
              <Link to="/waverley-rd">
                <img src={waverley} alt="Waverley Road" />
              </Link>
              <Link to="/waverley-rd">Waverley Road, Malvern East VIC</Link>
            </li>
            <li>
              <Link to="/bunda-st">
                <img src={bunda} alt="Bunda Street" />
              </Link>
              <Link to="/bunda-st">Bunda Street, Cairns QLD</Link>
            </li>
            <li>
              <Link className="btn" to="/contact">Enquire for more details</Link>
            </li>
          </ul>
        </Fade>
      </section>
    )
  }
}

export default Banner