import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade';

class Footer extends Component {
  render() {

    return (
      <footer className='footer'>
        <Fade bottom cascade distance="30px">
          <div className='footer__inner'>
            <div className="footer__logo col">
              <Link to='/' className='footer__title'>Collective Capital</Link>
            </div>
            <div className="footer__address col">
              <h4>Collective Capital Investment</h4>
              <p>AFSL Licence No: 516708<br /><br />1270 High Street, Armadale VIC 3143<br />
              PO Box 478, Malvern VIC 3144</p>
              <p><a href="mailto:info@collectivecapital.com.au">info@collectivecapital.com.au</a></p>
            </div>
            <div className="footer__team col">
              <h4>Nicholas Thompson</h4>
              <p><a href="mailto:nicholas@collectivecapital.com.au">nicholas@collectivecapital.com.au</a><br />
              <a href="tel:+61407385942">+61 407 385 942</a></p>
            </div>
            <div className="footer__team col">
              <h4>Dale O'Dwyer</h4>
              <p><a href="mailto:dale@collectivecapital.com.au">dale@collectivecapital.com.au</a><br />
              <a href="tel:+61431253321">+61 431 253 321</a></p>
            </div>
          </div>
          <p className="credits">Site by <a href="https://space-studio.co" target="_blank" rel="noopener noreferrer">Space Studio</a> &amp; <a href="https://codedrips.com" target="_blank" rel="noopener noreferrer">CodeDrips</a></p>
        </Fade>
      </footer>
    )
  }
}

export default Footer
