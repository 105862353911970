import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'

import Projects from '../components/projects'

import '../assets/scss/main.scss'

import favicon from '../assets/images/favicon.ico'

class Layout extends Component {

  render() {
    let { children } = this.props 
    return (
      <>
        <Helmet
          title={`Collective Capital`}
          meta={[
            { name: 'description', content: 'Collective Capital Investments is a highly specialised Australian based property investment group.' },
            { name: 'keywords', content: 'collective capital property investments melbourne' },
          ]}
        >
          <html lang="en" />
          <link rel="shortcut icon" href={favicon} type="image/x-icon" />
        </Helmet>
        <div className="wrapper">
          <div className="wrapper__site">
            <Header />
            <main className="site-wrapper">
              { children }
            </main>
            <Footer />
          </div>
          <div className="wrapper__projects">
            <Projects />
          </div>
        </div>

      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
