import React, { Component } from 'react'
import { Link } from 'gatsby'


class Header extends Component {

  state = {
    active: false,
    menuActive: false,
    overlay: false
  }

  constructor(props) {
    super(props)

    this._toggleOffCanvas = this._toggleOffCanvas.bind(this)
    this._hideOffCanvas = this._hideOffCanvas.bind(this)
    this._toggleProjects = this._toggleProjects.bind(this)
    this._showProjects = this._showProjects.bind(this)

  }

  _toggleOffCanvas() {
    if ( this.state.menuActive === true ) {
      this.setState({menuActive: false});
      document.querySelector('body').classList.remove('menu-open');
    } else {
      this.setState({menuActive: true});
      document.querySelector('body').classList.add('menu-open');
    }
  }

  _hideOffCanvas() {
    this.setState({menuActive: false});
    document.querySelector('body').classList.remove('menu-open');
  }

  _toggleProjects() {
    this._hideOffCanvas()
    document.querySelector('body').classList.toggle('projects-open');
  }

  _showProjects() {
    this._hideOffCanvas()
    document.querySelector('body').classList.add('projects-open');
  }

  render() {

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <div>
        <header className='header' role='banner'>
          <div className='header__inner'>
            <button id="burger" onClick={this._toggleOffCanvas} className={ this.state.menuActive ? 'active' : '' }>
              <span className="text">Menu</span>
              <span className="lines"></span>
            </button>
            <Link to='/' className='header__title'>Collective Capital</Link>
            <button className="hamburger hamburger--projects" onClick={this._toggleProjects}>
              <span className="text">Investments</span>
              <span className="lines"></span>
            </button>
          </div>
        </header>
        <div className={ `${this.state.menuActive ? 'off-canvas active' : 'off-canvas'}` }>
          <div className="off-canvas__inner">
            <ul className="off-canvas__nav">
              <li><Link to="/" {...props}>About</Link></li>
              <li><span onClick={this._showProjects}>Investments</span></li>
              <li><Link to="/contact" {...props}>Contact</Link></li>
            </ul>
            <ul className="off-canvas__buttons">
              <li><a className="btn" href="mailto:info@collectivecapital.com.au">Email</a></li>
              <li><a className="btn" href="https://au.linkedin.com/company/collective-capital-investments?trk=ppro_cprof">LinkedIn</a></li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
